import React, { useEffect, useState, useLayoutEffect } from 'react';
import bg from "./assets/Bg1.jpg";
import logo from "./assets/whiteLogo.png";
import "./App.css"
import { Typography, Icon, Box } from '@material-ui/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldOutlineLow from "@amcharts/amcharts4-geodata/worldOutlineLow";
import Table from '@material-ui/core/Table';
import ReactCountryFlag from "react-country-flag"
import { Stack } from '@mui/system';
import packageJson from '../package.json';

am4core.useTheme(am4themes_animated);
var finalData;
const POLLINGTIMEOUT = 60 * 60 * 1000;
var clearTimmerSync;

function sleep(ms) {
    return new Promise(resolveFunc => setTimeout(resolveFunc, ms));
}
var chart

export default function MalThreatModule() {

    const [countryCode, setCountryCode] = useState({});
    const [finalData1, setFinalData1] = useState(null);
    const [category, setcategory] = useState({});
    const [allPoints, setAllPoints] = useState([]);
    const [displaylist, setDisplaylist] = useState([]);
    const [tableCollapse, setCollapse] = useState({
        t1: false,
        t2: false,
        t3: false,
    });
    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 900)
        return function cleanup() {
            clearInterval(timer)
        }

    },[]);
    useEffect(() => {
        function initAccessFn() {
            clearTimeout(clearTimmerSync);
            try {
                var uri = `/api/v1/threatmap`;
                fetch(uri, {
                    method: "get",
                    headers: { "Content-Type": "application/json" },
                }).then(response => {
                    clearChart(chart)
                    response.json().then(data => {
                        finalData = data;
                        for (let x in finalData["plotdots"]) {
                            if (finalData["plotdots"][x]["city"] !== null) {
                                finalData["plotdots"][x]["city"] = "(" + finalData["plotdots"][x]["city"] + ")";
                            }
                        }
                        let category = finalData['category'];
                        let display = finalData['display'];
                        let country_code = finalData['country_codes'];
                        setCountryCode(country_code);
                        setDisplaylist(display);
                        setcategory(category);
                        setFinalData1(finalData);
                        setAllPoints(finalData.plotdots);
                    })
                })
            } catch (err) {
                console.error(err);
            }
            clearTimmerSync = setTimeout(() => {
                initAccessFn();
            }, POLLINGTIMEOUT);
        }
        initAccessFn();
        return () => {
            clearTimeout(clearTimmerSync);
        }
    }, [])


    useLayoutEffect(() => {
        console.log('calllup')
        clearChart(chart)
        am4core.options.autoDispose = true;
        chart = am4core.create("chartdiv", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldOutlineLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();
        chart.maxZoomLevel = 1;
        chart.background.opacity = 0
        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Exclude Antartica
        polygonSeries.exclude = ["AQ"];

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;
        polygonSeries.fill = am4core.color("#ffee00")

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.fill = am4core.color("#000");
        polygonTemplate.polygon.fillOpacity = 0.6;
        // polygonTemplate.tooltipText = "{name}";
        polygonSeries.mapPolygons.template.fill = am4core.color("#ffffff");
        polygonSeries.mapPolygons.template.strokeOpacity = 0;
        polygonTemplate.polygon.fillOpacity = 2;
        polygonTemplate.interactionsEnabled = false;
        polygonSeries.mapPolygons.template.stroke = am4core.color("#7b0fff");

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = chart.colors.getIndex(0);

        // Add image series
        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.tooltip.animationDuration = 1;
        imageSeries.tooltip.showInViewport = false;
        imageSeries.tooltip.background.fillOpacity = 0.8;
        imageSeries.tooltip.getStrokeFromObject = true;
        imageSeries.tooltip.getFillFromObject = false;
        imageSeries.tooltip.hiddenState.transitionDuration = 2000;
        imageSeries.tooltip.defaultState.transitionDuration = 2000;
        imageSeries.tooltip.background.fill = am4core.color("#6000f0");
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.mapImages.template.tooltipHTML = "<span style='color: #000000; vertical-align: center; font-weight: bolder; font-size: 12pt; font-family: Helvetica;'><span style='color: {color};'>&#x25C9;</span>&ensp;{country}&ensp;{plotCity}&ensp;&#x27A1;&ensp;{strain}</span>";
        imageSeries.mapImages.template.propertyFields.url = "url";
        imageSeries.mapImages.template.showTooltipOn = "always";
        var defaultImageSeries = chart.series.push(new am4maps.MapImageSeries());
        defaultImageSeries.tooltip.animationDuration = 1;
        defaultImageSeries.tooltip.showInViewport = false;
        defaultImageSeries.tooltip.background.fillOpacity = 0.8;
        defaultImageSeries.tooltip.getStrokeFromObject = true;
        defaultImageSeries.tooltip.getFillFromObject = false;
        defaultImageSeries.tooltip.background.fill = am4core.color("#AED6F1");
        defaultImageSeries.mapImages.template.propertyFields.longitude = "longitude";
        defaultImageSeries.mapImages.template.propertyFields.latitude = "latitude";
        defaultImageSeries.mapImages.template.tooltipHTML = "<span style='color: #000000; vertical-align: center; font-weight: bolder; font-size: 12pt; font-family: Helvetica;'><span style='color: {color};'>&#x25C9;</span>&ensp;{country}&ensp;{city}&ensp;&#x27A1;&ensp;{strain}</span>";
        defaultImageSeries.mapImages.template.propertyFields.url = "url";
        defaultImageSeries.mapImages.template.showTooltipOn = "hover";

        var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.radius = 5;
        circle.propertyFields.fill = "color";
        circle.propertyFields.fillOpacity = "0.1";

        var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle2.radius = 2;
        circle2.propertyFields.fill = "color";
        circle2.propertyFields.fillOpacity = "0.1";

        circle2.events.on("inited", function (event) {
            animateBullet(event.target);
        })


        var circle3 = defaultImageSeries.mapImages.template.createChild(am4core.Circle);
        circle3.radius = 4;
        circle3.propertyFields.fill = "color";
        circle3.propertyFields.fillOpacity = "0.1";

        function animateBullet(circle) {
            var animation = circle.animate([{ property: "scale", from: 0, to: 10 }, { property: "opacity", from: 0.9, to: 0.1 }], 800, am4core.ease.circleInOut);
            animation.events.on("animationended", function (event) {
                animateBullet(event.target.object);
            })
        }
        if (finalData1 != null) {
            initcallback()
            console.log('started')
        }
        async function initcallback() {
            for (let x in finalData1["plotdots"]) {
                defaultImageSeries.addData(finalData1["plotdots"][x]);
            }
            for (let strn in allPoints) {
                imageSeries.tooltip.background.fill = am4core.color("#AED6F1");
                let pinPoint = {...allPoints[strn], color: allPoints[strn].color};
                if (pinPoint['city'] !== null){
                    pinPoint['plotCity'] = pinPoint['city']
                }
                imageSeries.addData(pinPoint);
                await sleep(14000/5);
                imageSeries.removeData(1);
            }
            initcallback();
        }
    }, [finalData1]);

    function clearChart(chart) {
        return () => {
            if (chart) {
                chart.dispose();
            }
        };
    }

    return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", backgroundImage: `url(${bg})`, backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover" }}>
            <div style={{ height: "10%" }} className='d-flex justify-content-between pt-16'>
                <div className="mb-2 p-8">
                    <a href="https://www.secneurx.com" target="_blank" rel="noreferrer"><img className="logo" src={logo} alt="logo" /></a>
                </div>
                <div className="mb-8 p-2">
                    <Typography id="heading" style={{ color: '#ffffff', fontWeight: 'bolder', fontFamily: "Open Sans,sans-serif" }}>CYBER THREAT MAP</Typography>
                </div>
                <div className="mb-8 p-2"style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <Typography id="dateText" style={{ color: '#ffffff', fontWeight: 'bolder', fontFamily: "Open Sans,sans-serif" }} className="mr-2">{date.toLocaleDateString()}</Typography>
                        <Typography id="dateText" style={{ color: '#ffffff', fontWeight: 'bolder', fontFamily: "Open Sans,sans-serif" }} className="ml-16">{date.toLocaleTimeString()}</Typography>
                    </div>
                </div>
            </div>
            <div style={{ height: "80%" }} className="d-flex">
                <div id="chartdiv" style={{ width: "100%", height: "100%", padding: "0" }}></div>
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "5px", marginBottom: "6px", alignSelf: "flex-end", marginRight: "16px", overflow: "hidden" }} className="responsive-footer">
                <label id="dateText" style={{ color: '#ffffff', fontWeight: 'bolder', fontFamily: "Open Sans,sans-serif" }}>{packageJson.buildVersion}</label>
            </div>
            <div style={{ height: "9%", display: 'flex', justifyContent: "space-between", position: "fixed", bottom: "5px", width: "100%", alignItems: "flex-end" }} className="p-2 responsive-footer" >
                <div style={{ border: "1px solid #ffffff", width: "29.5%", transition: "0.3s", overflow: "hidden", minHeight: "30px", height: (tableCollapse.t1 ? "25px" : '250px'), backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <Typography className="d-flex justify-content-between align-items-center px-2 " style={{ width: "100%", fontWeight: 'bolder', fontSize: '15pt', color: '#ffffff', borderBottom: "1px solid #D3D3D3"}}>Top Attacker's Infrastructure<i class="fa-solid fa-angle-down" onClick={() => setCollapse({ ...tableCollapse, t1: !tableCollapse.t1 })}></i></Typography>
                    <Box style={{ paddingLeft: '2%', borderBottom: "0px solid #D3D3D3", overflowY: "auto", height: "217px", border: "0 solid transparent", display: "block" }}>
                        {displaylist["Top Attacker's Infrastructure"] && displaylist["Top Attacker's Infrastructure"].slice(0, 6).map((row1) => {
                            return (
                                <Stack key={row1} style={{ color: "#ffffff" }} direction="row">
                                    {countryCode[row1] ?
                                        <Typography style={{ color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}>
                                            <ReactCountryFlag countryCode={countryCode[row1]} svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '1.5em',
                                                }}
                                                title="US" /></Typography> :
                                        <Typography style={{ color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}><Typography style={{ color: "#000", fontSize: "12pt", fontFamily: "Helvetica" }}><Icon style={{ color: category[row1], fontSize: "12pt", fontWeight: 'bolder' }}>album</Icon></Typography></Typography>}
                                    <Typography style={{ color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}>{row1}</Typography>
                                </Stack>)
                        })}
                    </Box>
                </div>
                <div style={{ border: "1px solid #ffffff", width: "40%", transition: "0.3s", overflow: "hidden", minHeight: "30px", height: (tableCollapse.t3 ? "25px" : '250px'), backgroundColor: "rgba(0,0,0,0.8)" }}>
                    <Typography style={{ width: "100%", fontWeight: 'bolder', fontSize: '15pt', color: '#ffffff', borderBottom: "1px solid #D3D3D3" }} className="d-flex justify-content-between align-items-center px-2">Threat Infrastructure<i class="fa-solid fa-angle-down" onClick={() => setCollapse({ ...tableCollapse, t3: !tableCollapse.t3 })}></i></Typography>
                    <div className="" height="217px" style={{ overflow: "hidden" }}>
                        <table className="table trrr slider" aria-label="simple table" style={{ animationDuration: `${allPoints.length*3}s`, paddingLeft: '2%', borderBottom: "0px solid #D3D3D3", border: "0 solid transparent", display: "block" }}>
                            {allPoints.map((row, i) => {
                                return (
                                    <tr style={{ color: "#ffffff", borderBottom: "0px solid #D3D3D3" }} className="tabl-row">
                                        <td style={{ width: "5%", color: "#ffffff", fontSize: "10pt", border: "0px solid #D3D3D3", padding: "4px 4px 6px 20px" }}>
                                            <div className="d-flex">
                                                <Typography style={{ color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica" }}>
                                                    <Icon style={{ color: row['color'], fontSize: "12pt" }}>album</Icon>&ensp;{row['country']}&ensp;{row["city"] && row["city"] !== row["country"] && row["city"]}
                                                </Typography>
                                                <Typography>&ensp;<Icon style={{ fontSize: "18pt", color: "#ffffff", marginTop: '1.5%' }}>arrow_right_alt</Icon>&ensp;</Typography>
                                                <Typography style={{ color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica" }}>{row['strain']}</Typography>
                                            </div>
                                        </td>
                                    </tr>)
                            })}
                        </table>
                    </div>
                </div>                
                <div style={{ border: "1px solid #ffffff", width: "29.5%", transition: "0.3s", overflow: "hidden", minHeight: "30px", height: (tableCollapse.t2 ? "25px" : '250px'), backgroundColor: "rgba(0,0,0,0.8)" }}>
                    <Typography style={{ width: "100%", fontWeight: 'bolder', fontSize: '15pt', color: '#ffffff', borderBottom: "1px solid #D3D3D3", marginBottom: "4px" }} className="d-flex justify-content-between align-items-center px-2">Top Malware Types<i class="fa-solid fa-angle-down" onClick={() => setCollapse({ ...tableCollapse, t2: !tableCollapse.t2 })}></i></Typography>
                    <Table className="table" aria-label="simple table" style={{ paddingLeft: '2%', borderBottom: "0px solid #D3D3D3", overflowY: "auto", height: "217px", border: "0 solid transparent", display: "block" }}>
                        {displaylist["Top Malware Types"] && displaylist["Top Malware Types"].slice(0, 6).map((row1) => {
                            return (
                                <Stack key={row1} style={{ color: "#ffffff" }} direction="row">
                                    {countryCode[row1] ?
                                        <Typography style={{ width: "7%", color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}> <ReactCountryFlag countryCode={countryCode[row1]} svg
                                            style={{
                                                width: '1.5em',
                                                height: '1.5em',
                                            }}
                                            title={countryCode[row1]} /></Typography> :
                                        <Typography style={{ width: "7%", color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}><Typography style={{ color: "#000", fontSize: "12pt", fontFamily: "Helvetica" }}><Icon style={{ color: category[row1], fontSize: "12pt", fontWeight: 'bolder' }}>album</Icon></Typography></Typography>}
                                    <Typography style={{ width: "7%", color: "#ffffff", fontSize: "12pt", fontFamily: "Helvetica", border: "0px solid #D3D3D3", padding: "4px 4px 4px 20px" }}>{row1}</Typography>
                                </Stack>)
                        })}
                    </Table>
                </div>
            </div>
        </div>
    )
}